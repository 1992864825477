import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PrintService } from 'src/app/services/print.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-print-order-details',
  templateUrl: './print-order-details.component.html',
  styleUrls: ['./print-order-details.component.scss']
})
export class PrintOrderDetailsComponent implements OnInit {
  @Input() info;
  @Input() titleName: String;
  @Input() profile: boolean;
  orders: any;
  dataLoaded: boolean;
  printsTable = [];
  filteredPrintsTable = [];
  // @Input() order;
  categories = [];
  search = '';
  //collapsed: boolean

  columns: any;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();


  constructor(public dataService: DataService, private printService: PrintService) {
    this.dataLoaded = false;
  //  this.collapsed = false;
  }

  async ngOnInit() {
    await this.initData();
  }

  async initData() {
    this.printsTable = this.info;

    // first.details.properties.forEach(element => {
    //   this.columns.push(element.category_description)
    // });

    this.dataSource.data = this.printsTable;
 
    // for (const pr of this.orders) {
    //   const model = {
    // //     //     prints: pr
    //     collapsed: false
    //   };
    //   this.orders.push(model);
    // }
    this.dataLoaded = true;
  }

  async searchPrint() {
    try {
      if (this.search === '') {
        this.printsTable = this.printsTable;
      } else {
        //  this.filteredPrintsTable = this.printsTable;
        this.printsTable = this.printsTable.filter(item => item.prints.id === +this.search);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getPrintOptions(print: any) {
    try {
      let response = await this.printService.getPrintOptions(print)
      let options = ['Α/Α', 'Αρχείο', 'Σελίδες', 'Αντίτυπα', 'Προσανατολισμός σελίδας', 'Μορφοποίηση σελίδας'];
      print.details = []

      response.every(function(element, index) {
        if (element.file_id) {
          element.properties.forEach((property, index) => {
            options.push(property.category_description)
          });
         return false
        }
        return true
      })

      response.forEach(element => {
        if (element.file_id) {
          print.details.push(element)
        }
      });
      print.columns = options
    } catch (error) {
      console.log(error);
    }
  }

  async openPrintOptions(print: any) {
    print.loading = true;
    if (print.details === undefined) {
      await this.getPrintOptions(print)
    }
    print.loading = false;
    print.collapsed = !print.collapsed
  }
}
