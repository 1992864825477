<!--<div fxLayout="column">-->
<!--  <div fxLayout="column" *ngIf="dataLoaded" fxLayoutAlign.xs="center center center">-->
<!--    <div fxLayoutAlign="center" style="margin: 10px" class="mat-title">Ιστορικό εκτυπώσεων</div>-->
<!--    <div fxLayout="row wrap">-->
<!--      <mat-card class="card-config" *ngFor="let item of userOrders">-->
<!--        <mat-card-content>-->
<!--          <div>Ημερομηνία εκτύπωσης: {{item.date}}</div>-->
<!--          <div>Τιμή: {{item.price}}</div>-->
<!--          <div>Κατάσταση παραγγελίας:{{item.state}}</div>-->
<!--          <div fxLayoutAlign="center" style="margin: 5px">Επιλογές εκτύπωσης</div>-->
<!--          <mat-divider></mat-divider>-->
<!--          <div *ngFor="let opt of item.printOptions">-->
<!--            <div *ngIf="opt.options.length > 0">-->
<!--              {{opt.categoryDescription}}: {{opt.options[0].description}}-->
<!--            </div>-->
<!--            <div *ngIf="opt.options.length === 0">{{opt.categoryDescription}}: {{opt.value}}</div>-->
<!--          </div>-->
<!--        </mat-card-content>-->
<!--      </mat-card>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div *ngIf="dataLoaded" style="width: 100%;height:100%">
  <app-print-order-details titleName="Εκκρεμείς εκτυπώσεις" [info]="orders"></app-print-order-details>
</div>
