<!--<mat-toolbar fxHide.gt-xs style="background: royalblue"><mat-icon (click)="navigateHome()">keyboard_backspaces</mat-icon></mat-toolbar>-->

<div class="container">
  <div *ngIf="is_guest && !fileSelected" fxLayout="row"  fxLayoutAlign="center">
    <div fxLayoutAlign="center">
        <div class="space" style="text-align: start;">
          <h1 fxHide.lt-md style="margin-top: 50px;">Καλωσορίσατε στην εφαρμογή εκτύπωσης</h1>
          <h1 fxHide.lt-md style="margin-top: 10px;">Δημιουργήστε το λογαριασμό σας σήμερα για να:</h1>

          <div fxHide.lt-md class="info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
            <span style="font-size:medium">Πραγματοποιήσετε εκτυπώσεις</span>
          </div>
          <div fxHide.lt-md class="info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
            <span style="font-size:medium">Να παρακολουθήσετε τη πορεία των εκτυπώσεών σας</span>
          </div>
          <div fxHide.lt-md class="info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
            <span style="font-size:medium">
          Να διαχειριστείτε το λογαριασμό σας
        </span>
          </div>
          <h1 fxHide.lt-md style="margin-top: 15px;">
            Διαφορετικά, μπορείτε να πραγματοποιήσετε νέα εκτύπωση χωρίς εγγραφή παρακάτω:
          </h1>

          <div fxHide.gt-sm class="sm-title" style="margin-top: 50px; margin: 10px;">Καλωσορίσατε στην εφαρμογή εκτύπωσης</div>
          <h1 fxHide.gt-sm class="sm-title" style="margin-top: 10px; margin: 5px;">Δημιουργήστε το λογαριασμό σας σήμερα για να:</h1>
          <div fxHide.gt-sm class="sm-info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba;">check_circle</mat-icon>
            <span style="font-size:medium">Πραγματοποιήσετε εκτυπώσεις</span>
          </div>
          <div fxHide.gt-sm class="sm-info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
            <span style="font-size:medium">Να παρακολουθήσετε τη πορεία των εκτυπώσεών σας</span>
          </div>
          <div fxHide.gt-sm class="sm-info-item">
            <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
            <span style="font-size:medium">
          Να διαχειριστείτε το λογαριασμό σας
        </span>
          </div>
          <h1 fxHide.gt-sm class="sm-title" style="margin-top: 15px; margin: 10px;">
            Διαφορετικά, μπορείτε να πραγματοποιήσετε νέα εκτύπωση χωρίς εγγραφή παρακάτω:
          </h1>

          <!--
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 50px;">Καλωσορίσατε στην εφαρμογή εκτύπωσης</h1>
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 10px;">Δημιουργήστε το λογαριασμό σας σήμερα για να:</h1>
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 10px;">
                  <mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon>
                <span style="font-size:medium">Πραγματοποιήσετε εκτυπώσεις</span>
              </h1>
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 10px;"><mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon><span style="font-size:medium">Να παρακολουθήσετε τη πορεία των εκτυπώσεών σας</span></h1>
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 10px;"><mat-icon style="padding-left: 10px; padding-right: 5px; color: #008cba">check_circle</mat-icon><span style="font-size:medium">Να διαχειριστείτε το λογαριασμό σας</span></h1>
                <h1 *ngIf="is_guest" fxHide.gt-sm style="margin-top: 10px;">Διαφορετικά, μπορείτε να πραγματοποιήσετε νέα εκτύπωση χωρίς εγγραφή παρακάτω:</h1> -->
        </div>
      </div>

  </div>
  <div fxLayout="column" [class.main-padding]="!is_guest">
    <!--  <div fxLayout="row" class="mat-title" fxLayoutAlign="center" style="margin-top: 20px">Νέα εκτύπωση</div>-->
      <div fxLayout="row" style="margin: 2%" fxLayoutAlign="center">
        <div fxLayout="column" [ngStyle.lt-md]="{'width': '100%'}" style="padding: 20px" class=" mat-elevation-z4 column-style" *ngIf="!paymentShow">
    <!--      <div fxLayout="row" class="mat-title" style="margin: 10px">Βήμα 1: Επιλογή αρχείων και επιλογών εκτύπωσης</div>-->
          <div style="margin: 10px">
    <!--        <mat-icon style="cursor: pointer;" *ngIf="!userExists()" [routerLink]="['/login']" routerLinkActive="active">keyboard_backspaces</mat-icon>-->
            <div fxLayoutAlign="start center center" class="mat-title">Βήμα 1: Επιλογή αρχείων και επιλογών εκτύπωσης</div>
          </div>

          <mat-divider></mat-divider>
          <div fxLayout="row" class="mat-subheading-2" fxLayoutAlign="start" style="margin: 10px">
            Μπορείτε να επιλέξετε έως 10 αρχεία. &nbsp; <span style="color: grey">(Αποδεκτός τύπος αρχείων μόνο pdf)</span>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="row" style="margin: 10px">
            <button color="secondary" [disabled]="!internet_connection" mat-raised-button type="button" class="button-config" (click)="fileInput.click()">
              <mat-icon style="margin: 5px">upload_file</mat-icon><span style="margin: 5px">Επιλέξτε αρχεία</span>
              <input #fileInput style="display:none;margin: 10px" type="file" accept=".pdf" multiple (change)="onFileSelected($event)"/>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxFlex fxLayoutGap="10px">
          <div fxLayout="row" fxFlex *ngFor="let file of filesToUpload;let y = index">
            <mat-accordion [ngStyle.xs]="{'width':'100%'}" [multi]="false">
              <mat-expansion-panel  class="file-header" #selectionPanel (opened)="selectionSelected(selectionPanel, y)">
                <mat-expansion-panel-header fxHide.lt-sm class="file-header">
                  <mat-panel-title style="width: 100%;">
                    <div class="filename">
                      <div>
                        <mat-icon matTooltip="Αφαίρεση αρχείου" (click)="removefile($event, file)">close</mat-icon>
                      </div>
                      <div style="margin-bottom: 3px; width: 100%" [ngStyle.gt-md]="{'margin-left': '10px'}">
                        <div style="overflow-wrap: break-word; display: block; width: 95%;">
                          Όνομα αρχείου: {{file.file.name}}
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel-header fxHide.gt-xs style="height: 80px"  class="file-header">
                  <mat-panel-title style="width: 100%;">
                    <div class="filename-sm">
                      <div>
                        <mat-icon matTooltip="Αφαίρεση αρχείου" (click)="removefile($event, file)">close</mat-icon>
                      </div>
                      <div style="  width: 100%" [ngStyle.gt-md]="{'margin-left': '10px'}">
                        <div style="overflow-wrap: break-word; display: block; width: 95%;">
                          Όνομα αρχείου: {{file.file.name}}
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
               <div fxLayout="column">
                 <div fxLayout="row" *ngIf="pageSizes[y] > 1">Το αρχείο αποτελείται από: <strong>{{pageSizes[y]}} σελίδες</strong></div>
                 <div fxLayout="row" *ngIf="pageSizes[y] <= 1">Το αρχείο αποτελείται από: <strong>{{pageSizes[y]}} σελίδα</strong></div>
                 <div fxLayout="row wrap" *ngIf="optionsLoaded" style="margin:10px;">
                   <div *ngFor="let category of file.printingOptions;let i = index">
                     <div *ngIf="category.properties.length > 0 && isValidCategory(category)">
                       <div fxLayout="row">
                         <div xLayout="column" fxLayoutAlign="center center center" fxLayoutGap="10">
                          <mat-label>{{category.description}}</mat-label>
                         </div>
                         <div xLayout="column" fxLayoutAlign="center center center" fxLayoutGap="10">
                          <mat-icon style="padding-left: 5px;" class="info-color" [matTooltip]="category.details">info</mat-icon>
                         </div>
                        </div>
                       <mat-button-toggle-group [(ngModel)]="file.selectedOptions[i]">
                         <div *ngFor="let option of category.properties" fxLayout="row">
                           <mat-button-toggle *ngIf="option.is_active !== 0" [disabled]="option.is_active === 0" disableRipple [value]="option">
                             {{option.description}}
                           </mat-button-toggle>
                         </div>
                       </mat-button-toggle-group>
                     </div>
                   </div>
                   <div>
                   <div><mat-label>Προσανατολισμός σελίδας</mat-label></div>
                   <mat-button-toggle-group [(ngModel)]="file.orientation">
                     <div fxLayout="row wrap">
                     <mat-button-toggle disableRipple [value]="0"> Οριζόντια</mat-button-toggle>
                     <mat-button-toggle disableRipple [value]="1"> Κάθετα</mat-button-toggle>
                     </div>
                   </mat-button-toggle-group>
                     </div>
                   <div>
                     <div><mat-label>Μορφοποίηση σελίδας</mat-label></div>
                   <mat-button-toggle-group [(ngModel)]="file.page">
                     <div fxLayout="row wrap">
                     <mat-button-toggle disableRipple [value]="0">Μονή σελίδα</mat-button-toggle>
                     <mat-button-toggle disableRipple [value]="1">Διπλή σελίδα</mat-button-toggle>
                     </div>
                   </mat-button-toggle-group>
                   </div>
                     <mat-form-field fxLayoutAlign="end center" fxHide.xs style="margin-top: 25px;">
                       <mat-label>Αντίτυπα</mat-label>
                       <input style="padding: 5px" type="number" [min]="1" [(ngModel)]="file.copies"
                              matInput required />
                     </mat-form-field>
                     <mat-form-field fxHide.gt-xs>
                       <mat-label>Αντίτυπα</mat-label>
                       <input style="padding: 5px" type="number" [min]="1" [(ngModel)]="file.copies"
                              matInput required />
                     </mat-form-field>
                 </div>
               </div>
              </mat-expansion-panel>
            </mat-accordion>
            </div>
          </div>
          <div *ngIf="filesToUpload.length" fxLayout="row" style="margin: 10px" fxLayoutAlign="start">
            <mat-form-field style="width: 100%;">
              <mat-label>Σχόλια</mat-label>
              <textarea style="padding: 5px" [(ngModel)]="notes"
                     matInput></textarea>
            </mat-form-field>
          </div>
          <div fxLayout="row" style="margin: 10px" fxLayoutAlign="end">
            <div>
              <button mat-raised-button [disabled]="!isValid()" color="secondary" (click)="savePrint()">Επόμενο</button>
            </div>
          </div>
    <!--      <mat-divider></mat-divider>-->
    <!--      <div fxLayout="row" fxLayoutGap="10px" style="margin: 10px" fxLayoutAlign="space-between center">-->
    <!--        <div>Επιλογές Εκτύπωσης</div>-->
    <!--        <mat-icon style="cursor: pointer">add</mat-icon>-->
    <!--      </div>-->
        </div>

    <!--    Second card for payment-->
        <div fxLayout="column" style="padding: 20px" [ngStyle.sm]="{'width': '80%'}" [ngStyle.xs]="{'width': '100%'}" class="mat-elevation-z4 column-style" *ngIf="paymentShow">
          <div style="margin: 10px">

            <div fxLayoutAlign="start center center" class="mat-title"><mat-icon style="cursor: pointer; margin-right: 10px;" (click)="goBack()">keyboard_backspaces</mat-icon> Βήμα 2: Λεπτομέρειες Παραγγελίας</div>
          </div>
          <mat-divider></mat-divider>
          <div class="mat-subheading-2" fxLayoutAlign="start" style="margin: 10px">Τρόπος πληρωμής
            <mat-icon style="margin: 2px">payment</mat-icon></div>
          <div fxLayout="row" style="margin: 20px">
            <mat-radio-group
              [(ngModel)]="paymentSelected"
              (change)="paymentChanged($event)">
              <mat-radio-button color="primary" style="margin: 30px" *ngFor="let payment of paymentMethod" [value]="payment.id">
                {{payment.description}} <span *ngIf="payment.price !== null" style="color: grey"> +{{payment.price}}€</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
<!--  //TODO PAYMENT PLEASE DO NOT REMOVE -->
    <!--          <div *ngIf="online_payment">-->
<!--            <form accept-charset="UTF-8"-->
<!--            action="https://eurocommerce-test.cardlink.gr/vpos/shophandlermpi"-->
<!--            id="PaymentForm"-->
<!--            method="POST">-->
<!--              <input name="version" type="hidden" value="2"/>-->
<!--              <input name="mid" type="hidden" value="0024096349"/>-->
<!--              <input name="lang" type="hidden" value=""/>-->
<!--              <input name="deviceCategory" type="hidden" value=""/>-->
<!--              <input name="orderid" type="hidden" value="{{paymentId}}"/>-->
<!--              <input name="orderDesc" type="hidden" value=""/>-->
<!--              <input name="orderAmount" type="hidden" value="0.05"/>-->
<!--              <input name="currency" type="hidden" value="EUR"/>-->
<!--              <input name="payerEmail" type="hidden" value=""/>-->
<!--              <input name="payerPhone" type="hidden" value=""/>-->
<!--              <input name="billCountry" type="hidden" value="EL"/>-->
<!--              <input name="billState" type="hidden" value="test"/>-->
<!--              <input name="billZip" type="hidden" value="test"/>-->
<!--              <input name="billCity" type="hidden" value="test"/>-->
<!--              <input name="billAddress" type="hidden" value="test"/>-->
<!--              <input name="weight" type="hidden" value=""/>-->
<!--              <input name="dimensions" type="hidden" value=""/>-->
<!--              <input name="shipCountry" type="hidden" value=""/>-->
<!--              <input name="shipState" type="hidden" value=""/>-->
<!--              <input name="shipZip" type="hidden" value=""/>-->
<!--              <input name="shipCity" type="hidden" value=""/>-->
<!--              <input name="shipAddress" type="hidden" value=""/>-->
<!--              <input name="addFraudScore" type="hidden" value=""/>-->
<!--              <input name="maxPayRetries" type="hidden" value=""/>-->
<!--              <input name="reject3dsU" type="hidden" value=""/>-->
<!--              <input name="payMethod" type="hidden" value=""/>-->
<!--              <input name="trType" type="hidden" value=""/>-->
<!--              <input name="extInstallmentoffset" type="hidden" value=""/>-->
<!--              <input name="extInstallmentperiod" type="hidden" value=""/>-->
<!--              <input name="extRecurringfrequency" type="hidden" value=""/>-->
<!--              <input name="extRecurringenddate" type="hidden" value=""/>-->
<!--              <input name="blockScore" type="hidden" value=""/>-->
<!--              <input name="cssUrl" type="hidden" value=""/>-->
<!--              <input name="confirmUrl" type="hidden" value=""/>-->
<!--              <input name="cancelUrl" type="hidden" value=""/>-->
<!--              <input name="extXOrderId" type="hidden" value=""/>-->
<!--              <input name="extTokenOptions" type="hidden" value=""/>-->
<!--              <input name="extToken" type="hidden" value=""/>-->
<!--              <input name="var1" type="hidden" value=""/>-->
<!--              <input name="var2" type="hidden" value=""/>-->
<!--              <input name="var3" type="hidden" value=""/>-->
<!--              <input name="var4" type="hidden" value=""/>-->
<!--              <input name="var5" type="hidden" value=""/>-->
<!--              <input name="var6" type="hidden" value=""/>-->
<!--              <input name="var7" type="hidden" value=""/>-->
<!--              <input name="var8" type="hidden" value=""/>-->
<!--              <input name="var9" type="hidden" value=""/>-->
<!--              <input name="digest" type="hidden" value=""/>-->

<!--            </form>-->
<!--            <button (click)="onlinePayment()">pay</button>-->

<!--          </div>-->
          <!--  *ngIf="hasShippingMethod(paymentSelected)" -->
          <div *ngIf="paymentSelected">
              <mat-divider></mat-divider>
              <div class="mat-subheading-2" fxLayoutAlign="start" style="margin: 10px">Αποστολή
                <mat-icon style="margin: 2px">local_shipping</mat-icon>
              </div>
              <div fxLayout="row" style="margin: 20px">
                <mat-radio-group [(ngModel)]="shipping_id" (change)="shippingChanged($event)">
                  <mat-radio-button color="primary" style="margin: 30px" *ngFor="let ship of shippingOptions" [value]="ship.id"
                  [disabled]="availableShippings(ship.id)">
                    {{ship.description}} <span *ngIf="ship.price > 0" style="color: grey"> +{{+ship.price + ship.extraShipCost}}€</span>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
          </div>
          <!-- <mat-divider *ngIf="!hasShippingMethod(paymentSelected)"></mat-divider> -->

          <!-- <div fxLayout="row" style="margin: 20px">
            <div class="terms">
              <mat-checkbox [(ngModel)]="showPaymentForm" color="primary" style="margin-right: 5px;" class="terms"></mat-checkbox>Κάντε κλικ εδώ εάν επιθυμείτε να λάβετε τιμολόγιο ή απόδειξη.
            </div>
          </div> -->

          <div *ngIf="!userExists()">
            <mat-divider></mat-divider>
          </div>

          <div class="mat-subheading-2" fxLayoutAlign="start" *ngIf="!userExists()" style="margin: 10px">

            Συμπληρώστε τα στοιχεία σας

            <mat-icon style="margin: 2px">assignment_ind</mat-icon></div>
            <div fxLayout="row" style="margin: 10px;" *ngIf="!userExists()" fxLayoutAlign="start">
              <form fxLayout="column" [formGroup]="guestUserForm">

                <table class="address_form">
                  <tr>
                    <td>
                      <mat-label>Όνομα:</mat-label>
                    </td>
                    <td >
                      <mat-form-field>
                        <input placeholder="Όνομα" matInput formControlName="firstName" required/>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-label>Επώνυμο:</mat-label>
                    </td>
                    <td>
                      <mat-form-field >
                        <input placeholder="Επώνυμο" matInput formControlName="lastName" required/>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-label>Email:</mat-label>
                    </td>
                    <td>
                      <mat-form-field >
                        <input placeholder="Email" matInput formControlName="email" required/>
                        <mat-error *ngIf="guestUserForm.get('email')?.value && guestUserForm.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-label>Τηλέφωνο:</mat-label>
                    </td>
                    <td>
                      <mat-form-field >
                        <input placeholder="Τηλέφωνο" matInput formControlName="phoneNumber" required/>
                        <mat-error *ngIf="guestUserForm.get('phoneNumber')?.value && guestUserForm.get('phoneNumber')?.invalid">Εισάγετε ένα έγκυρο τηλέφωνο.</mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-label>Διεύθυνση:</mat-label>
                    </td>
                    <td>
                      <mat-form-field >
                        <input placeholder="Διεύθυνση" matInput formControlName="address" required/>
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <mat-label>Τ.Κ:</mat-label>
                    </td>
                    <td>
                      <mat-form-field>
                        <input placeholder="Τ.Κ" matInput formControlName="postalCode" required/>
                        <mat-error *ngIf="guestUserForm.get('postalCode')?.value && guestUserForm.get('postalCode')?.invalid">Εισάγετε ένα έγκυρο T.K.</mat-error>
                      </mat-form-field>
                    </td>
                  </tr>
                </table>
              </form>

          </div>

          <mat-divider></mat-divider>

          <div class="mat-subheading-2" fxLayoutAlign="start" style="margin: 10px">Στοιχεία τιμολόγησης
            <mat-icon style="margin: 2px">receipt</mat-icon>
          </div>
          <div fxLayout="row" style="margin: 20px">
            <mat-radio-group [(ngModel)]="paymentSelection">
              <div fxLayout="row" fxLayoutAlign="start">
                <mat-radio-button style="margin: 30px" color="primary" disableRipple [value]="1" >Tιμολόγιο</mat-radio-button>
                <mat-radio-button  style="margin: 30px" color="primary" disableRipple [value]="0" >Aπόδειξη</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxLayout="row" style="width: 100%;">
            <div style="width: 100%;" *ngIf="paymentSelection === 1">
              <div>

                <ng-container *ngIf="!is_guest">
                  <div fxHide.lt-lg style="padding-left: 20px;" class="invoice_selection">
                    <div *ngIf="!new_invoice && userInvoices.length !== 0">
                      <mat-form-field style="width: 200px">
                        <mat-label>Επιλέξτε τιμολόγιο</mat-label>
                        <mat-select [(ngModel)]="selectedInvoice">
                            <mat-option *ngFor="let invoice of userInvoices" [value]="invoice.id">
                            {{invoice.company_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="or-divider" *ngIf="!new_invoice && userInvoices.length !== 0">
                      <span>Ή</span>
                    </div>
                    <div>
                      <button *ngIf="!new_invoice" (click)="newInvoice()" mat-raised-button class="custom-button">Συμπληρώστε νέo τιμολόγιο</button>
                      <button *ngIf="new_invoice" (click)="closeNewInvoice()" mat-raised-button class="custom-button">Ακύρωση</button>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="!is_guest">
                  <div fxHide.gt-md class="invoice_selection_sm">
                    <div fxLayoutAlign="center center center" *ngIf="!new_invoice && userInvoices.length !== 0">
                      <mat-form-field style="width: 240px">
                        <mat-label>Επιλέξτε τιμολόγιο</mat-label>
                        <mat-select [(ngModel)]="selectedInvoice">
                            <mat-option *ngFor="let invoice of userInvoices" [value]="invoice.id">
                            {{invoice.company_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="or-divider" *ngIf="!new_invoice && userInvoices.length !== 0">
                      <span>Ή</span>
                    </div>
                    <div style="padding-top: 10px;">
                      <button *ngIf="!new_invoice" (click)="newInvoice()" style="width: 240px" mat-raised-button class="custom-button">Συμπληρώστε νέo τιμολόγιο</button>
                      <button *ngIf="new_invoice" (click)="closeNewInvoice()" style="width: 240px" mat-raised-button class="custom-button">Ακύρωση</button>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="is_guest || new_invoice">
                  <span style="margin-left: 10px;"  *ngIf="is_guest">
                    Κάντε σύνδεση <a class="link" (click)="quickLogin()">εδώ</a> για να επιλέξετε ενα υπάρχων τιμολόγιο
                  </span>
                  <h1 style="margin-left: 10px; margin-top: 20px;">
                    Συμπληρώστε νέο τιμολόγιο
                  </h1>
                  <form [formGroup]="invoiceForm" style="margin: 10px;">
                    <table class="invoice_form">
                      <tr width="100%">
                        <td>
                          <mat-label>Όνομα εταιρείας:</mat-label>
                        </td>
                        <td >
                          <mat-form-field>
                            <input matInput placeholder="Όνομα εταιρείας" formControlName="company_name" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Επάγγελμα:</mat-label>
                        </td>
                        <td>
                          <mat-form-field>
                            <input matInput placeholder="Επάγγελμα" formControlName="occupation" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Διεύθυνση 1:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Διεύθυνση 1"  formControlName="address1" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Διεύθυνση 2:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Διεύθυνση 2"  formControlName="address2"/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Πόλη:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Πόλη"  formControlName="city" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Περιοχή:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Περιοχή"  formControlName="area" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Τ.Κ:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Τ.Κ"  formControlName="postal_code" required/>
                            <mat-error *ngIf="invoiceForm.get('postal_code')?.value && invoiceForm.get('postal_code')?.invalid">Εισάγετε ένα έγκυρο T.K.</mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Χώρα:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Χώρα"  formControlName="country" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Email:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Email"  formControlName="invoice_email" required/>
                            <mat-error *ngIf="invoiceForm.get('invoice_email')?.value && invoiceForm.get('invoice_email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Α.Φ.Μ:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Α.Φ.Μ"  formControlName="vat_number" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Δ.Ο.Υ.:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Δ.Ο.Υ."  formControlName="tax_office" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </form>
                </ng-container>
              </div>
            </div>
            <!-- <div style="width: 100%;" *ngIf="paymentSelection === 0">
              <div>

                <ng-container *ngIf="!is_guest" >
                  <div fxHide.lt-lg style="padding-left: 20px; width: 200px;" class="invoice_selection">
                    <div *ngIf="!new_receipt && availableReceipts.length !== 0">
                      <mat-form-field style="width: 200px">
                        <mat-label>Επιλέξτε απόδειξη</mat-label>
                        <mat-select [(ngModel)]="selectedReceipt">
                            <mat-option *ngFor="let receipt of availableReceipts" [value]="receipt.id">
                            {{receipt.first_name}}
                            {{receipt.last_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="or-divider" *ngIf="!new_receipt && availableReceipts.length !== 0">
                      <span>Ή</span>
                    </div>
                    <div>
                      <button *ngIf="!new_receipt" (click)="newReceipt()" mat-raised-button class="custom-button">Συμπληρώστε νέα απόδειξη</button>
                      <button *ngIf="new_receipt" (click)="closeNewReceipt()" mat-raised-button class="custom-button">Ακύρωση</button>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="!is_guest">
                  <div fxHide.gt-md  style="width: 100%" class="invoice_selection_sm">
                    <div fxLayoutAlign="center center center" *ngIf="!new_receipt && availableReceipts.length !== 0">
                      <mat-form-field style="width: 230px">
                        <mat-label>Επιλέξτε απόδειξη</mat-label>
                        <mat-select [(ngModel)]="selectedReceipt">
                            <mat-option *ngFor="let receipt of availableReceipts" [value]="receipt.id">
                            {{receipt.first_name}}
                            {{receipt.last_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="or-divider" *ngIf="!new_receipt && availableReceipts.length !== 0">
                      <span>Ή</span>
                    </div>
                    <div fxLayoutAlign="center center center" style="padding-top: 10px;">
                      <button *ngIf="!new_receipt" (click)="newReceipt()" style="width: 230px" mat-raised-button class="custom-button">Συμπληρώστε νέα απόδειξη</button>
                      <button *ngIf="new_receipt" (click)="closeNewReceipt()" style="width: 230px" mat-raised-button class="custom-button">Ακύρωση</button>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="is_guest || new_receipt">
                  <span style="margin-left: 10px;" *ngIf="is_guest">
                    Κάντε σύνδεση <a class="link" (click)="quickLogin()">εδώ</a> για να επιλέξετε μία υπάρχουσα απόδειξη
                  </span>
                  <h1 style="margin-left: 10px; margin-top: 20px;">
                    Συμπληρώστε νέα απόδειξη
                  </h1>
                  <form [formGroup]="receiptForm" style="margin: 10px;">
                    <table class="receipt_form">
                      <tr>
                        <td>
                          <mat-label>Όνομα:</mat-label>
                        </td>
                        <td >
                          <mat-form-field>
                            <input matInput placeholder="Ονομα" formControlName="first_name" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Επώνυμο:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="Επώνυμο" formControlName="last_name" required/>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <mat-label>Email:</mat-label>
                        </td>
                        <td>
                          <mat-form-field >
                            <input matInput placeholder="email"  formControlName="email" required/>
                            <mat-error *ngIf="receiptForm.get('email')?.value && receiptForm.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </form>
                </ng-container>

              </div>
            </div> -->
          </div>

          <mat-divider></mat-divider>

          <div *ngIf="is_guest">
            <div fxLayout="row" style="margin: 10px; " fxLayoutAlign="end center center">
              <div class="terms">
                <mat-checkbox [(ngModel)]="terms" color="primary" style="margin-right: 5px; margin-left: 10px;" class="terms"></mat-checkbox>Αποδέχομαι τους <span [routerLink]="" class="link" style="cursor: pointer;" (click)="openTerms()">όρους χρήσης</span>.
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>

          <div fxLayout="row" style="margin: 10px" fxLayoutAlign="center center center">
            <div>
              <button [disabled]="!validGuestForm()" mat-raised-button (click)="completeOrder()" color="secondary" class="custom-button2">Ολοκλήρωση</button>
            </div>
          </div>

          <mat-divider></mat-divider>
        </div>

        <div class="space-window" fxHide.lt-md *ngIf="display_total">
        </div>


        <div [ngStyle.lt-md]="{'display': 'none'}" fxHide.lt-md >
          <div class="total-window" id="total_window" [ngStyle.lt-md]="{'display': 'none'}" fxHide.lt-md #totalWindow *ngIf="display_total">

            <div fxLayout="column" style="width: 100%; margin-left: 20px; margin-right: 20px;" fxHide.lt-md>
              <div *ngIf="display_total">
                <div class="mat-title">Σύνολο</div>
                <mat-divider></mat-divider>
                <div class="mat-subheading-2" style="margin: 10px">Αρχεία</div>

                <div class="files-container" style="max-height: 65vh; overflow-y: auto;">

                  <mat-accordion>
                    <mat-expansion-panel *ngFor="let file of this.filesToUpload; let index = index" #costPanel (opened)="costSelected(costPanel, index)">
                      <mat-expansion-panel-header class="right-aligned-header">
                        <mat-panel-title>
                          Όνομα αρχείου:  <strong> {{file.file.name}}</strong>
                        </mat-panel-title>
                        <mat-panel-description>
                          {{file.cost | number:'1.2-2' }}€
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <div fxLayout="column"  class="total-window-details">
                        <mat-divider></mat-divider>
                        <mat-divider class="big-divider" style="border-top-width: 5px;"></mat-divider>

                        <ng-container *ngFor="let option of printingOptions; let index = index;">
                          <ng-container *ngIf="option.properties.length > 0 && isValidCategory(option) && file.selectedOptions[index]">
                              <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div style="margin: 10px">{{option.description}}</div>
                                <div *ngIf="file.selectedOptions[index].has_scale===0" style="margin-right: 10px">{{file.selectedOptions[index]?.price}}€</div>
                                <div *ngIf="file.selectedOptions[index].has_scale===1" style="margin-right: 10px">{{this.scalePrice}}€</div>
                              </div>
                              <mat-divider></mat-divider>
                          </ng-container>
                        </ng-container>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                        </div>
                        <ng-container *ngIf="file.orientation !== null">
                          <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div style="margin: 10px">Προσανατολισμός σελίδας</div>
                            <div style="margin-right: 10px">{{getOrientationName(file.orientation)}}</div>
                          </div>
                          <mat-divider></mat-divider>
                        </ng-container>
                        <ng-container *ngIf="file.page !== null" >
                          <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div style="margin: 10px">Μορφοποίηση σελίδας</div>
                            <div style="margin-right: 10px">{{getPageName(file.page)}}</div>
                          </div>
                          <mat-divider></mat-divider>
                        </ng-container>

                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div style="margin: 10px">Αντίτυπα</div>
                          <div style="margin-right: 10px">x {{file.copies}}</div>
                        </div>

                        <mat-divider></mat-divider>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                          <div style="margin: 10px">
                            <span class="total-title">Σύνολο</span>
                          </div>
                          <div style="margin-right: 10px">
                            <span class="total-title">{{file.cost | number:'1.2-2'}}€</span>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>


                  <ng-container *ngIf="shipping_id !== undefined">
                    <mat-divider class="big-divider" style="border-top-width: 5px;"></mat-divider>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div style="margin: 10px">Αποστολή</div>
                      <div style="margin-right: 10px">{{getCurrentShippingName()}}</div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="paymentSelected !== undefined">
                    <mat-divider></mat-divider>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div style="margin: 10px">Τρόπος πληρωμής</div>
                      <div style="margin-right: 10px">{{getCurrentPaymentName()}}</div>
                    </div>
                  </ng-container>

                </div>


                <mat-divider class="big-divider" style="border-top-width: 5px;"></mat-divider>
                <div style="margin: 10px" class="mat-title">Πληρωτέο ποσό: {{getTotal()}}€ <span *ngIf="getTotal() > 0">*</span></div>
                <div style="margin: 10px">
                  <strong *ngIf="getTotal() > 0">* Η τιμολόγηση ενδέχεται να τροποποιηθεί</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--    show total at right side-->
      </div>

      <!--    show total down mobile view-->
      <div fxLayout="column" fxHide.gt-sm style="padding: 20px; bottom: 0;" *ngIf="display_total">
        <div class="mat-title" style="margin: 10px" fxLayoutAlign="start">Σύνολο</div>
        <mat-divider></mat-divider>
        <div class="mat-subheading-2" style="margin: 10px">Αρχεία</div>
        <div fxLayout="column" *ngFor="let file of this.filesToUpload" style="margin: 10px">
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{file.file.name}}</div>
            <div>{{file.cost | number:'1.2-2' }}€</div>
          </div>
          <mat-divider class="big-divider"></mat-divider>

          <ng-container *ngFor="let option of printingOptions; let index = index;">
            <ng-container *ngIf="option.properties.length > 0 && isValidCategory(option) && file.selectedOptions[index]">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>{{option.description}}</div>
                  <div>{{file.selectedOptions[index]?.price}}}€</div>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="file.orientation !== null">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>Προσανατολισμός σελίδας</div>
              <div>{{getOrientationName(file.orientation)}}</div>
            </div>
            <mat-divider></mat-divider>
          </ng-container>
          <ng-container *ngIf="file.page !== null" >
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>Μορφοποίηση σελίδας</div>
              <div>{{getPageName(file.page)}}</div>
            </div>
            <mat-divider></mat-divider>
          </ng-container>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Αντίτυπα</div>
            <div>x {{file.copies}}</div>
          </div>

          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 5px;">
            <div>
              Σύνολο
            </div>
            <div>
              {{file.cost | number:'1.2-2' }}€
            </div>
          </div>
        </div>

        <ng-container *ngIf="shipping_id !== undefined">
          <mat-divider class="big-divider" style="border-top-width: 5px;"></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div style="margin: 10px">Αποστολή</div>
            <div style="margin-right: 10px">{{getCurrentShippingName()}}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="paymentSelected !== undefined">
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div style="margin: 10px">Τρόπος αποστολής</div>
            <div style="margin-right: 10px">{{getCurrentPaymentName()}}</div>
          </div>
        </ng-container>
        <mat-divider class="big-divider" style="border-top-width: 5px;"></mat-divider>

        <div style="margin: 10px" class="mat-title">Πληρωτέο ποσό: {{getTotal()}}€</div>
      </div>
    </div>
</div>
