<h1 mat-dialog-title>
  <div class="header">
    <span style="font-size: larger; padding-right: 20px;">Ευχαριστούμε για την πληρωμή σας!</span>
    <mat-icon (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>

<div mat-dialog-content>
  <div class="default-message">
    Η συναλλαγή σας είναι σε εξέλιξη και θα ολοκληρωθεί εντός 1-2 εργάσιμων ημερών.
    Μπορείτε να κατεβάσετε το αποδεικτικό πληρωμής.
  </div>
</div>


<div mat-dialog-actions align="center">
  <div class="buttons-container">
    <button class="custom-button" mat-button (click)="downloadPDF()">
      Κατέβασμα σε PDF
    </button>
  </div>
</div>
