<h1 mat-dialog-title>
    <div class="header">
      <span style="font-size: larger;">Οροι Χρήσης</span>
      <mat-icon  [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
    </div>
</h1>
   

<div class="container" mat-dialog-content>
    <div [innerHtml]="terms"></div>
    <!-- <div class="header">
        <h1>1.1 Όρος Χρήσης</h1>
    </div>
    <p class="content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ultrices nunc eu leo sodales faucibus. Integer eleifend eget sapien quis cursus. Fusce pulvinar aliquet nunc non pretium. Nullam id augue vitae nunc efficitur pretium. Cras tincidunt tincidunt turpis vitae interdum. Mauris non faucibus tellus. Pellentesque nunc ligula, posuere ac sapien commodo, vehicula sodales orci. In iaculis velit sit amet convallis finibus. Aenean sem lectus, malesuada et vulputate vel, elementum at metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam ipsum ante, imperdiet eget ante nec, scelerisque lobortis ligula.
    </p>
    <div class="header">
        <h1>1.2 Όρος Χρήσης</h1>
    </div>
    <p class="content">
        Sed porta pulvinar egestas. Phasellus ipsum sapien, efficitur eu pellentesque quis, tristique eu nulla. Mauris dictum turpis quis ligula congue mollis. Morbi sagittis sapien mi, eget euismod arcu viverra at. Phasellus molestie efficitur est, at suscipit dolor aliquam ut. Morbi molestie quam odio, et semper urna sagittis scelerisque. Praesent mauris nulla, tempus nec volutpat id, finibus sed tortor. Mauris consequat leo vel lectus eleifend scelerisque. Curabitur dignissim nec leo sit amet luctus. Donec scelerisque felis sed tellus sollicitudin tristique. Sed in luctus lorem, quis laoreet justo. Cras massa erat, tempus nec magna at, feugiat dapibus tellus. In hac habitasse platea dictumst. Aliquam eget lorem sit amet erat scelerisque pharetra a at enim.
    </p>
    <div class="header">
        <h1>1.3 Όρος Χρήσης</h1>
    </div>
    <p class="content">
        Fusce mollis tortor ut suscipit porttitor. Nullam malesuada turpis id tellus ultricies interdum. Maecenas nec luctus nibh, non consectetur nunc. Nam nec ante eget nibh cursus vehicula. Sed finibus, lorem et vehicula aliquam, massa libero condimentum dolor, eu iaculis sapien quam in est. Nulla facilisi. Nulla vestibulum quis velit eu dictum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas ornare porta odio, vitae dictum tortor consequat at. Donec finibus ullamcorper congue. Phasellus vulputate sem ante, aliquet malesuada tellus efficitur gravida. Pellentesque eget erat nec nunc vulputate bibendum. Suspendisse eget pulvinar tellus, a pellentesque risus.
    </p>
    <div class="header">
        <h1>2.1 Όρος Χρήσης 2</h1>
    </div>
    <p class="content">
        Fusce mollis tortor ut suscipit porttitor. Nullam malesuada turpis id tellus ultricies interdum. Maecenas nec luctus nibh, non consectetur nunc. Nam nec ante eget nibh cursus vehicula. Sed finibus, lorem et vehicula aliquam, massa libero condimentum dolor, eu iaculis sapien quam in est. Nulla facilisi. Nulla vestibulum quis velit eu dictum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas ornare porta odio, vitae dictum tortor consequat at. Donec finibus ullamcorper congue. Phasellus vulputate sem ante, aliquet malesuada tellus efficitur gravida. Pellentesque eget erat nec nunc vulputate bibendum. Suspendisse eget pulvinar tellus, a pellentesque risus.
    </p>
    <div class="header">
        <h1>2.2 Όρος Χρήσης 2</h1>
    </div>
    <p class="content">
        Curabitur rhoncus libero velit, nec imperdiet enim dapibus vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consectetur lectus arcu, nec placerat tellus molestie vestibulum. Nulla cursus maximus bibendum. Vivamus ut consequat odio. Quisque at nibh sed ex consectetur tempus. Praesent ut ante vel libero posuere sodales eget ut elit. Nulla scelerisque ex ex, a consectetur lectus tempus non. Duis feugiat sed mi at dictum. Nullam nec cursus sem. Aliquam lobortis nec erat sed aliquet. Etiam ante nunc, convallis vitae metus id, aliquam posuere nisl. Vivamus gravida lorem ultrices orci consequat tempus sed nec massa. Donec non sagittis purus, sit amet dignissim eros. Quisque at ipsum volutpat neque porta ultricies.
    </p>
    <div class="header">
        <h1>3 Όρος Χρήσης 3</h1>
    </div>
    <p class="content">
        Sed id purus sed justo molestie commodo sed aliquet dolor. Sed rutrum nec metus eget vehicula. Quisque at lorem risus. Ut volutpat ante in orci tempor malesuada. Aliquam placerat cursus fringilla. Fusce et ex eget justo finibus faucibus vitae a ligula. Ut scelerisque nunc non turpis vulputate, eget ornare sapien ultrices. Duis dictum velit eget urna interdum rhoncus. Curabitur facilisis eget nisl vel auctor. Nunc vel condimentum elit. Fusce auctor justo euismod erat cursus pulvinar. Praesent imperdiet magna a eros ornare, sit amet vehicula massa dapibus. Etiam mollis faucibus pretium. Maecenas quis mi nibh. 
    </p> -->
</div>

<div mat-dialog-actions align="end" *ngIf="!preview">
    <button *ngIf="logout" mat-raised-button [mat-dialog-close]="{logout: true}" class="custom-button" cdkFocusInitial>Αποσύνδεση</button>
    <button mat-raised-button [mat-dialog-close]="{accept: true}" class="custom-button" cdkFocusInitial>Αποδέχομαι</button>
</div>

<div mat-dialog-actions align="end" *ngIf="preview">
    <button mat-raised-button [mat-dialog-close]="" class="custom-button" cdkFocusInitial>Κλείσιμο</button>
</div>
