import { Component, OnInit } from '@angular/core';
import {PrintService} from '../../services/print.service';
import {DataService} from '../../services/data.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';


@Component({
  selector: 'app-pending-order-details',
  templateUrl: './pending-order-details.component.html',
  styleUrls: ['./pending-order-details.component.scss']
})
export class PendingOrderDetailsComponent implements OnInit {
  orders = [];
  dataLoaded: boolean;
  data: any;

  constructor(private printService: PrintService, public dataService: DataService, private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      // if (JSON.parse(localStorage.getItem('model')) !== null) {
      //   this.data = JSON.parse(localStorage.getItem('model'));
      //   this.dataService.orders = JSON.parse(localStorage.getItem('model'));
      // } else {
      //   this.data = [];
      //   this.dataService.orders = [];
      // }
      this.progressSpinnerDialogService.show();
      const orders = await this.printService.getPendingOrders();

      for (const data of orders) {
        this.orders.push(data);
      }
 
      this.progressSpinnerDialogService.end();
      this.dataLoaded = true;
    } catch (e) {
      this.progressSpinnerDialogService.end();

      console.log(e);
    }
  }


}
