import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { CommonService } from './common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPaymentDialogComponent } from '../components/confirm-payment-dialog/confirm-payment-dialog.component';
import { ActionCompletionDialogComponent } from '../components/action-completion-dialog/action-completion-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private commonService: CommonService, private matDialog: MatDialog, private router: Router) {
  }

  async produceDigest(payment: any) {
    try {
      return await this.commonService.postRequest('payment/digest', payment)
    } catch (e) {
        console.log(e)
      throw e;
    }
  }

  handlePaymentResult(activatedRoute): void {
    const id = activatedRoute.snapshot.queryParams.id;
    const mode = activatedRoute.snapshot.queryParams.mode;

    if (mode === 'CANCELED') {
      this.openActionCompletionDialog('Η συναλλαγή σας ακυρώθηκε.', 'Ακυρωμένη συναλλαγή');
    }
    if (mode === 'ERROR') {
      this.openActionCompletionDialog('Η συναλλαγή σας δεν ολοκληρώθηκε.', 'Υπήρξε κάποιο πρόβλημα');
    }
    if (mode === 'REFUSED') {
      this.openActionCompletionDialog('Η συναλλαγή σας απορρίφθηκε.', 'Απορριφθείσα συναλλαγή');
    }
    if (mode === 'confirm_payment') {
      this.router.navigate([], {
        queryParamsHandling: ''
      }).then(() => {
        this.matDialog.open(ConfirmPaymentDialogComponent, {
          data: {
            id
          }
        });
      });
    }
  }
 
  openActionCompletionDialog(message: string, title: string): void {
    this.router.navigate([], {
      queryParamsHandling: ''
    }).then(() => {
      this.matDialog.open(ActionCompletionDialogComponent, {
        data: {
          message,
          title
        }
      });
    });
  }
}
