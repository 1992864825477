
<div>
    <h1 mat-dialog-title>
        <div class="header">
            <span style="font-size: larger; margin-right: 30px;"> Αλλαγή κωδικού πρόσβασης</span>
            <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
        </div>
    </h1>
    <div class="container"> 
        <div class="fw">
           <div class="mat-subheading-1" fxLayoutAlign="start">
            <mat-icon class="icon-config info-color" style="margin-right: 5px; ">info</mat-icon>Συμπληρώστε ένα νέο κωδικό πρόσβασης. 
            </div>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div fxLayout="row" fxLayoutAlign="space-between" style="width: 100%; margin-top: 20px;">
                <div fxLayout="column" fxFlex="10">
                    <mat-icon class="info-color" style="margin-top: 10px;">vpn_key</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="90">
                    <mat-form-field  class="fw" hintLabel="Τουλάχιστον 8 χαρακτήρες, με ένα κεφαλαίο γράμμα και έναν αριθμό.">
                        <mat-label>Νέο συνθηματικό</mat-label>
                        <input (keyup)="onPasswordChange()" [type]="hide ? 'password' : 'text'" formControlName="password" matInput required/>
                        <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                          matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" style="width: 100%; margin-top: 20px;">
                <div fxLayout="column" fxFlex="10">
                    <mat-icon class="info-color" style="margin-top: 10px;">vpn_key</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="90">
                    <mat-form-field  class="fw">
                        <mat-label>Επανάληψη Συνθηματικού</mat-label>
                        <input (keyup)="onPasswordChange()" [type]="hide_confirm ? 'password' : 'text'" formControlName="confirm_password" matInput required/>
                       <mat-error  *ngIf="!validFields(true)">Οι κωδικοί δεν είναι ίδιοι.</mat-error>
                        <mat-icon style="cursor: pointer;" (click)="hide_confirm = !hide_confirm"
                          matSuffix>{{hide_confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="custom-button" [disabled]="!validFields()" (click)="submit()">Αποθήκευση</button>
</div>

