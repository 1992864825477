import { UserService } from 'src/app/services/users/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';
import { TermsComponent } from '../terms/terms.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent implements OnInit {

  isLinear = true;
  usernameFormGroup: FormGroup;
  detailsFormGroup: FormGroup;
  hide = true;
  terms = false;

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<RegisterDialogComponent>,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private userService: UserService,
              private toastMessagesService: ToastMessagesService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.usernameFormGroup = this.formBuilder.group({
      username: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.minLength(8),
          Validators.required,
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
        ]),
      ],
    });
    this.detailsFormGroup = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required
        ]),
      ],
      surname: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required
        ]),
      ],
      email: ['', [Validators.compose([Validators.required, Validators.email])]],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.maxLength(10),
          Validators.minLength(10)
        ]),
      ],
      address: ['', Validators.required],
      postal_code: [
        '',
        Validators.compose([
          Validators.maxLength(45),
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.maxLength(5),
          Validators.minLength(5)
        ]),
      ],
    });
  }

  async registerUser() {
    try {
      // const userInfo = this.userInfoForm.value;
      const user = {
        // id: null,
        first_name: this.detailsFormGroup.get('name').value,
        last_name: this.detailsFormGroup.get('surname').value,
        email: this.detailsFormGroup.get('email').value,
        username: this.usernameFormGroup.get('username').value,
        password: this.usernameFormGroup.get('password').value,
        is_admin_user: 0,
        created_by: null,
        address: this.detailsFormGroup.get('address').value,
        postal_code: this.detailsFormGroup.get('postal_code').value,
        phone_number: this.detailsFormGroup.get('phone').value
      };
      console.log(user);

      this.progressSpinnerDialogService.show();

      await this.userService.registerUser(user);

      this.progressSpinnerDialogService.end();

      this.toastMessagesService.toastSuccessMessage('Ο λογαριασμός σας δημιουργήθηκε με επιτυχία.');

      this.closeDialog(true)
    } catch (e) {

      this.progressSpinnerDialogService.end();

      console.log(e);
      this.toastMessagesService.toastErrorMessage(e.error.message);
    }
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

  async openTerms() {
    const dialog = this.dialog.open(TermsComponent, { data: {logout: false}});

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.terms = true
      }
    });
  }

  validForm() {
    return this.terms && this.usernameFormGroup.valid
  }

}
