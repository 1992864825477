<h1 mat-dialog-title>
  <div class="header">
    <span style="font-size: larger;">Δημιουργία χρήστη</span>
    <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>

<mat-horizontal-stepper [linear]="isLinear"  #stepper style="width: 100%; margin-top: 15px;">
    <mat-step [stepControl]="usernameFormGroup" class="form">
      <form [formGroup]="usernameFormGroup" style="width: 100%;">

        <ng-template matStepLabel>Όνομα χρήστη</ng-template>

        <div class="form" style="margin-top: 20px;">
          <div  fxLayout="row" style="width: 60%;" >
            <div fxLayout="column" fxFlex="30" fxFlexAlign="center center center">
              <div style="margin-top: 20px; margin-right: 10px;">
                Όνομα Χρήστη:
              </div>
              <div style="margin-top: 30px; margin-right: 10px;">
                Συνθηματικό:
              </div>
            </div>
            <div fxLayout="column" fxFlex="70">
              <mat-form-field>
                <input matInput [maxLength]="45" formControlName="username" placeholder="Όνομα χρήστη" required/>
              </mat-form-field>
              <mat-form-field hintLabel="Τουλάχιστον 8 χαρακτήρες, με ένα κεφαλαίο γράμμα και έναν αριθμό.">
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Συνθηματικό" required/>
                <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                          matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
<!--
        <div   style="background-color: red; height: 100%; width: 100%; margin-top: 20px;">
          <div fxLayout="column" fxLayoutAlign="center center center" style="width: 100%; height: 100%">
            <div fxLayout="row" style="width: 100%;" >
              <div fxLayout="column" fxFlex="10">
                <div class="info-color" style="margin-top: 20px"><mat-icon>person</mat-icon></div>
                <div class="info-color" style="margin-top: 20px"><mat-icon>vpn_key</mat-icon></div>
              </div>
              <div fxLayout="column" fxFlex="90">
                <mat-form-field>
                  <input matInput formControlName="username" placeholder="Όνομα χρήστη" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Συνθηματικό" required/>
                  <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                            matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div> -->
      </form>
      <div fxLayout="row" style="margin: 10px; margin-top: 20px" fxLayoutAlign="end center center">
        <div class="terms">
          <mat-checkbox [(ngModel)]="terms" color="primary" style="margin-right: 5px; margin-left: 10px;" class="terms"></mat-checkbox>Αποδέχομαι τους <a [routerLink]="" style="cursor: pointer;" (click)="openTerms()">όρους χρήσης</a>.
        </div>
      </div>
      <div mat-dialog-actions align="end" style="padding-bottom: 20px;">
        <button mat-raised-button class="custom-button" [disabled]="!validForm()" matStepperNext>Επόμενο</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="detailsFormGroup" label="Πληροφορίες χρήστη">
      <form [formGroup]="detailsFormGroup">

        <div fxLayout="column" style="height: 100%; width: 100%; margin-top: 10px;">
          <div fxLayout="column" fxLayoutAlign="center start center" style="width: 100%; height: 100%">
            <div fxLayout="row" style="width: 100%;">
              <div fxLayout="column" fxFlex="45">
                <div style="margin-top: 20px">Όνομα:</div>
                <div style="margin-top: 40px">Επώνυμο:</div>
                <div style="margin-top: 40px">Email:</div>
                <div style="margin-top: 40px">Τηλ. επικοινωνίας:</div>
                <div style="margin-top: 40px">Διεύθυνση:</div>
                <div style="margin-top: 40px">ΤΚ:</div>
              </div>
              <div fxLayout="column" fxFlex="55">
                <mat-form-field>
                  <input [maxLength]="45" matInput formControlName="name" placeholder="Όνομα" required/>
                </mat-form-field>
                <mat-form-field>
                  <input [maxLength]="45" matInput formControlName="surname" placeholder="Επώνυμο" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="email" placeholder="Email" required/>
                  <mat-error *ngIf="detailsFormGroup.get('email')?.value && detailsFormGroup.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="phone" placeholder="Τηλ. επικοινωνίας" required/>
                  <mat-error *ngIf="detailsFormGroup.get('phone')?.value &&detailsFormGroup.get('phone')?.invalid">Εισάγετε ένα έγκυρο τηλέφωνο.</mat-error>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="address" placeholder="Διεύθυνση" required/>
                </mat-form-field>
                <mat-form-field>
                  <input matInput formControlName="postal_code" placeholder="ΤΚ" required/>
                  <mat-error *ngIf="detailsFormGroup.get('postal_code')?.value &&detailsFormGroup.get('postal_code')?.invalid">Εισάγετε ένα έγκυρο T.K.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="actions">
          <button mat-raised-button class="custom-button-close" matStepperPrevious>Πίσω</button>
          <button style="margin-left: 10px;" [disabled]="!detailsFormGroup.valid" mat-raised-button class="custom-button" (click)="registerUser()">Εγγραφή</button>
        </div>

      </form>
    </mat-step>

</mat-horizontal-stepper>

